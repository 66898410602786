body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,sans-serif,catamaran,montserrat,Quicksand;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:catamaran;
}
/* @media (orientation: portrait) {
  body {
    width:100vh;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
} */
.vjs-menu-button-popup .vjs-menu .vjs-menu-content{
  background-color: #161616 !important;
  color: #ffff !important;
}

.video-js .vjs-play-progress{
  background-color: #007a78 !important;
}