/*counter*/
.day,
.hour,
.minute,
.second {
  font-size: 20px;
  margin-right: 5px;
  font-weight: 700;
}
.mob-cellicon {
  display: none;
}
.mobile-hamburger-icon {
  position: absolute;
  right: 15px;
  top: 23px;
  display: none;
}
/* =================newly Added=================>>> */
.plan-logo-user {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  top: -10px;
}
.subscribe-bac {
  height: 36px;
  width: 135px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(92.8deg, #cc9730 5.98%, #d9a603 99.19%); */
  background: #007a78;
  color: #fff;
  margin-left: 10px;
}

.subscribe-placeholder {
  height: 35px;
  width: 125px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #fff;
}

.subscribe-bac img {
  margin-right: 5px;
  margin-bottom: 2px;
}
.subscribe-bac-mob {
  height: 35px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #d9a603;
  margin-top: 15px;
}

.subscribe-bac-mob img {
  margin-right: 5px;
  margin-bottom: 2px;
}

.subscribe-bac-mob li {
  padding: 0px;
}

.edit-text-mob {
  font-size: 14px;
  margin-left: 35px;
}
.edit-txt-img-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-txt-img-sec img {
  width: 25px !important;

  height: 25px !important;
}
.edit-text-mob li {
  padding-top: 0 !important;
}

.header-all {
  width: 100%;
  /* height: 100%; */
  /* position: relative; */
  position: sticky;
  top: 0;
  z-index: 2000;
  background-color: #ffffff;
}
.headertop {
  background-color: #ffffff;
  z-index: 3000;
  width: 100%;
  height: 75px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0.02px 10px rgba(0, 0, 0, 0.12);
}
.searchinput {
  position: relative;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  width: 190px;
  height: 30px;
  top: 10px;
  left: 30px;
  border-radius: 5px;
  font-size: 14px;
}
.logo-bx {
  width: 150px;
  height: 50px;
  cursor: pointer;
}
.loginbtn {
  border: 0.5px solid #007a78;
  box-sizing: border-box;
  border-radius: 2px;
  width: 135px;
  height: 35px;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  color: #007a78;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginbtn:hover {
  background-color: #007a78;
  color: #ffffff;
}
.navbarlink li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 14px;
}
.exp-popup-col li {
  font-size: 14px;
  font-weight: 400;
}
.bm-burger-button {
  display: none;
}
.searchbox-icon {
  display: none;
}
.cart-link {
  position: relative;
}

/*explore pop up*/

.explore-popup-wrapper {
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
  height: 100vh;
  border-top: 1px solid #dadada;
}

.pop-up {
  background: #ffffff;
  /* transform: translate(-1234px,2%); */
  padding: 10px 0px 25px 93px;
  box-shadow: 0px 15px 10px rgb(0 0 0 / 12%);
  text-align: left;
}

.pop-up h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.Cato_type_explore {
  width: 20px;
}
.pop-sub-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
/* .pop-up .pop-content-wrap {
  display: flex;
  flex-direction: row;
} */
.Search-value-div {
  height: 100%;
  width: 100%;
}
.cart-num {
  height: 22px;
  width: 22px;
  text-align: center;
  border-radius: 50%;
  background: #007a78;
  font-size: small;
  position: absolute;
  top: -26px;
  color: #ffffff;
  right: 24px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-btn {
  width: 28px;
  height: 28px;
}
.user-profile-pop-wrapper {
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
  height: 100vh;
  cursor: auto;
}
.Explore-div-main {
  width: 114%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.Explore-div-main ul {
  margin-bottom: 5px !important;
}

.user-profile-pop-up-sec {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  padding: 15px;
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
  margin-right: 75px;
}

.profile-pop-up-instructor-text {
  margin-left: 37px;
  font-weight: 600;
}

.profile-pop-up-text a {
  font-weight: 600;
}

.sidebar-profile-section {
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
}
.sidebar-profile-section img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.sidebar-profile-section h2 {
  font-family: Montserrat;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
  color: #007a78;
  margin-bottom: 10px;
  padding-top: 30px;
}
.sidebar-profile-section h4 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #007a78;
}

.des-explore-hov {
  cursor: pointer;
}

.header-nav-hov a:hover {
  color: #007a78;
}

.des-explore-hov span:hover {
  color: #007a78;
}
.onheader-click-color {
  color: #007a78 !important;
}

.explore-head .header-nav-hov a:focus {
  color: #007a78;
}

/* /blog hover open/ */
#blog-head {
  position: relative;
}
#blog-head:hover a {
  color: #007a78;
  cursor: pointer;
}
#blog-head:hover .blog-inner-wrap {
  display: block;
}

#program-head {
  position: relative;
}
#program-head:hover span {
  color: #007a78;
  cursor: pointer;
}
#program-head:hover .popup-program-in {
  display: block;
}

/* .popup-program-in {
  background: #ffffff;
 
  padding: 15px;
  box-shadow: 0px 15px 10px rgb(0 0 0 / 12%);
  text-align: left;
} */

.popup-program-in {
  position: absolute;
  top: 40px;
  right: -20px;
  width: 352px;
  /* height: 500px; */
  padding: 18px;
  background: #fff;
  box-shadow: 0px 15px 10px rgb(0 0 0 / 12%);
  display: none;
}
.popup-program-in::after {
  content: "";
  width: 195px;
  height: 45px;
  transform: rotate(0deg);
  background: rgb(255, 255, 255);
  position: absolute;
  /* box-shadow: 1px 4px 8px rgb(0 0 0 / 50%); */
  z-index: 0;
  top: -20px;
  left: calc(61% - 61px);
}

/*expplore*/

#explore-head {
  /* position: relative; */
}
#explore-head p {
  margin: 0;
}
#explore-head:hover p {
  color: #007a78;
  cursor: pointer;
}
#explore-head:hover .explore-pop-up {
  display: block;
}

.explore-pop-up {
  position: absolute;
  width: 100%;
  left: 0;
  top: 69px;
  background: #ffffff;
  /* transform: translate(-58.8%, 2%); */
  /* padding: 10px 200px; */
  box-shadow: 0px 15px 10px rgb(0 0 0 / 12%);
  text-align: left;
  display: none;
}

.explore-pop-up::after {
  content: "";
  /* width: 325px; */
  width: 420px;
  height: 29px;
  transform: rotate(0deg);
  background: rgb(255, 255, 255);
  position: absolute;
  /* box-shadow: 1px 4px 8px rgb(0 0 0 / 50%); */
  z-index: 0;
  top: -20px;
  left: calc(54% - 61px);
}
.des-explore-hov span:focus {
  color: #007a78;
}

.program-wraper-head {
  position: absolute;
  right: 14%;
  /* top: 75px; */
  width: 360px;
  height: 100vh;
  border-top: 1px solid #dadada;
}

.program-wraper-head-align {
  right: 30.5%;
}
.program-wraper-head span {
  color: #212121;
}
.program-wraper-head small {
  color: #212121;
}

.popup-program-in > div > img {
  width: 50px;
  height: 50px;
}
.popup-program-in > div {
  padding: 10px;
}
.popup-program-in > div:hover {
  background: rgba(198, 238, 229, 0.4);
  border-radius: 8px;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}
.mob-search-box {
  display: flex;
  align-items: center;
  width: 319px;
  height: 30px;
  background: rgba(213, 213, 213, 0.35);
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* .new-feature-tag {
  position: relative;
}

.new-feature-tag p {
  position: absolute;
  top: -15px;
  right: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  animation-name: shimmer;
  background: linear-gradient(90.15deg, #ffb13c 0.17%, #d08515 104.9%);

  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: shimmer;
}
.new-feature-tag-mob {
  position: relative;
}

.new-feature-tag-mob p {
  position: absolute;
  top: -5px;
  left: 75px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  animation-name: shimmer;
  background: linear-gradient(90.15deg, #ffb13c 0.17%, #d08515 104.9%);

  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: shimmer;
} */

.new-feature-tag-new {
  display: flex;
  align-items: center;
}
.new-feature-tag-new p {
  margin-bottom: 0;
  /* background: linear-gradient(180deg, #007a78 2.04%, #2da499 71.3%); */
  background-color: #01a08f;
  border-radius: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  /* identical to box height */
  letter-spacing: 1px;
  /* white */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
  width: 38px;
  padding: 4px 6px;
  margin-left: 5px;
}
.new-feature-tag {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  /* font-size: 11px; */
  line-height: 13px;

  /* 11 */

  background: linear-gradient(90.15deg, #ffb13c 0.17%, #d08515 104.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: shimmer 1s infinite ease-in-out;
}
/*blog*/

.header-blog-hov {
  cursor: unset;
}
.header-blog-hov:hover {
  color: #007a78;
}
.blog-wraper-head {
  position: absolute;
  left: 30%;
  /* top: 75px; */
  width: 360px;
  height: 100vh;
  border-top: 1px solid #dadada;
}
.blog-inner-wrap {
  position: absolute;
  top: 40px;
  right: -20px;
  width: 715px;
  /* height: 500px; */
  padding: 18px;
  background: #fff;
  box-shadow: 0px 15px 10px rgb(0 0 0 / 12%);
  display: none;
}
.blog-inner-wrap::after {
  content: "";
  width: 141px;
  height: 45px;
  transform: rotate(0deg);
  background: rgb(255, 255, 255);
  position: absolute;
  /* box-shadow: 1px 4px 8px rgb(0 0 0 / 50%); */
  z-index: 0;
  top: -20px;
  left: calc(88% - 61px);
}

.blog-cat-sec {
  display: flex;
  padding: 10px;
}
.blog-cat-sec:hover {
  /* background: rgba(224, 237, 234, 0.4); */
  border-radius: 8px;
}
.blog-cat-info-right {
  margin-left: 10px;
  margin-top: 5px;
}
.blog-cat-info-right h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.02em;
  text-transform: capitalize;
  margin-bottom: 12px;
  color: #212121;
}
.blog-cat-info-right a {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #212121 !important;
}
.blog-cat-info-right a:hover {
  text-decoration: underline;
}
.mob-blog-content {
  margin-bottom: 25px;
  border-bottom: 1px solid #cdcdcd;
  width: 60%;
}

.mob-blog-content h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  /* identical to box height, or 94% */

  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #212121;
}
.mob-blog-content a {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  margin-bottom: 11px;
  color: #212121;
}

.vist-blog-mob {
  text-decoration: underline;
  /* color: #007a78; */
  margin-left:17%;
}
@keyframes shimmer {
  0% {
    background: linear-gradient(90.15deg, #ffb13c 0.17%, #d08515 104.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  100% {
    background: linear-gradient(180.15deg, #ffb13c 0.17%, #d08515 104.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.dec-event-popwrap{
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../images/Homepage/dec banner-min.png");
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
  height: 55px;
  background-size: cover;
  background-position: center;
}

.dec-event-popwrap span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  margin-right: 18px;
  flex-wrap: wrap;
  display: flex;
}
.dec-evnt-popbtn{
  background: #FFFFFF;
  border-radius: 5px;
  padding: 5px 12px;
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 798px) {
  .mob-cellicon {
    display: block;
    position: absolute;
    left: 68% !important;
    /* top: 22%; */
  }
  .dec-event-popwrap span{
   font-size: 14px;
  }
  .dec-evnt-popbtn{
  font-size: 12px;
  padding: 3px 5px;

  }
}

/*tab*/
@media screen and (min-width: 768px) {
  .my-profile-mob-wrap {
    position: fixed;
    top: 55px;
    left: 66%;
    background: #fff;
    height: 100vh;
    width: 100%;
    transition: all 0.3s ease-in;
  }

  .pop-up-mob {
    position: fixed;
    top: 40px;
    /* left: 66%; */
    background: #fff;
    height: 100vh;
    width: 100%;
  }
  .pop-up-mob img {
    padding-bottom: 2px;
  }

  .pop-up-mob span {
    padding-left: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }

  .pop-up-mob .pop-content-wrap {
    /* padding-left: 47px; */
   
  }

  .pop-content-wrap {
     padding: 0px 110px;
  }

  .pop-content-wrap li {
    font-size: 14px;
    color: #acadad;
  }
  .pop-content-wrap img {
    padding-bottom: 2px;
  }

  .pop-content-wrap span {
    padding-left: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }
}

@media screen and (min-width: 1288px) and (max-width: 1440px) {
  .searchinput {
    width: 150px;
  }
  .subscribe-bac {
    width: 134px;
  }
  .navbarlink li {
    padding-right: 22px;
    font-size: 13px;
  }
}
/* @media screen and (min-width: 1150px) and (max-width: 1400px) {
  .searchinput {
    width: 190px;
  }
} */

@media screen and (min-width: 1550px) {
  .blog-wraper-head {
    left: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .my-profile-mob-wrap {
    position: fixed;
    top: 55px;
    left: 72%;
    background: #fff;
    height: 100vh;
    width: 100%;
    transition: all 0.3s ease-in;
  }
  .pop-up-mob {
    position: fixed;
    top: 55px;

    background: #fff;
    height: 100vh;
    width: 100%;
  }
}

@media only screen and (max-width: 1440px) {
  .des-explore-hov {
    cursor: pointer;
  }

  .des-explore-hov:hover .pop-up {
    display: block;
  }
}

@media screen and (max-width: 1439px) {
  .des-explore-hov {
    cursor: pointer;
  }

  .des-explore-hov:hover .pop-up {
    display: block;
  }

  .pop-up {
    position: absolute;
    width: 100%;
    left: 0;
    background: #ffffff;
    /* transform: translate(-54.8%, 2%); */
    /* padding: 10px 200px; */
    box-shadow: 0px 15px 10px rgb(0 0 0 / 12%);
    text-align: left;
  }
}
@media only screen and (max-width: 1340px) {
  .searchinput {
    position: relative;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    width: 140px !important;
    height: 30px;
    top: 10px;
    left: 30px;
  }
}

@media only screen and (max-width: 1284px) {
  .mob-cellicon {
    display: block;
    position: absolute;
    left: 83%;
  }

  .mobile-hamburger-icon {
    display: block;
  }
  .searchinput {
    display: none;
  }
  .navbarlink li {
    display: none;
  }

  .bm-burger-button {
    display: block;
    position: fixed;
    width: 35px;
    height: 35px;
    top: 71px;
    right: 15px;
  }
  .bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: right;
    text-decoration: none;
    transition: color 0.2s;
  }
  .searchbox-icon {
    position: absolute;
    display: block;
    left: 90%;
  }
  .headertop {
    justify-content: unset;
  }
  .loginbtn {
    display: none;
  }
  .cart-num {
    display: none;
  }
}
@media only screen and (max-width: 565px) {
  .mobile-hamburger-icon {
    display: block;
  }
  /*counter*/
  .day,
  .hour,
  .minute,
  .second {
    font-size: 13px;
    margin-left: 0px;
    font-weight: 700;
  }
  .headertop {
    padding-left: 10px;
    padding-right: 0px;
  }
  .searchbox-icon {
    position: absolute;
    display: block;
    left: 79%;
  }
}

/* <<<===========newly added============ */

li {
  list-style-type: none;
  padding-right: 30px;
  /* color: black !important; */
}
/* :any-link {
  color: #000000;
} */
.before-login-btn {
  display: flex;
  align-items: center;
}

/* <-- search bar --> */
.user-profile1 {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

#dropdown-bas {
  list-style-type: none;
  padding: 0;
}

#dropdown-bas li {
  float: right;
}

#dropdown-bas li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 5px;
  text-decoration: none;
  width: 110px;
}

#dropdown-bas li a:hover,
.dropdown:hover .dropbtn {
  background-color: #007a78;
  color: white;
  width: 110px;
}

/* #dropdown-bas li.dropdown {
    
  }
   */
#dropdown-bas .dropdown-content {
  display: none;
  position: relative;
  background-color: #d2efe8;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

#dropdown-bas .dropdown-content a {
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-align: left;
}

#dropdown-bas .dropdown:hover .dropdown-content {
  display: block;
}

.exp-popup-col li {
  padding-top: 9px;
  font-size: 16px;
  cursor: pointer;
}
.exp-popup-col li a:hover {
  color: #007a78 !important;
  font-weight: 600;
}
.userdrop-down li {
  padding-top: 7px;
  cursor: pointer;
}
.userdrop-down li:hover {
  background-color: #d2efe8;
}

/*  =========================mobile & tablets=================================================== */
/* .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    left: 210px;
    top: 25px;
    
  } */
.bm-burger-bars {
  background: #373a47;
}
.bm-burger-bars-hover {
  background: rgb(128, 122, 122);
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  width: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  left: 0;
  top: 0;
}

.side-bar-open-overlay {
  background-color: hsla(0, 0%, 0%, 0.582);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 11;
}

.close-side-bar-icon {
  width: auto;
  height: auto;
  position: absolute;
  top: 25px;
  right: 25px;
}
.side-navbar {
  font-family: Montserrat;
  /* user-select: none; */
  border: none;
  text-align: left;
  font-weight: 500;
  background-color: #fff;
  position: absolute;
  /* top: -50px; */
  height: 95vh;
  right: 0;
  padding: 25px;
  padding-top: 50px;
  width: 285px;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-navbar li {
  /* border-bottom: 1px dotted rgb(224, 219, 219); */
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
}
.Explore-main-div > ul {
  margin-bottom: 0px;
}

.search-sugg {
  position: absolute;
  bottom: -10;

  width: 100%;
  background: #f7f5f5;
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.search-sugg ul li {
  margin: 0 15px;
  padding: 10px 0px;
  font-size: 20px;
  border-bottom: 1px dashed rgb(199, 196, 196);
}

@media only screen and (max-width: 1100px) {
  /* .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    left: 64px;
    top: 25px;
  } */
  .login-btn {
    position: relative;
    margin-top: 18px;
    margin-bottom: 32px;
    margin-left: 600px;
    border: none;
    color: #007a78;
    height: 35px;
    width: 80px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 16px;
    font-weight: 700;
    text-align: Center;
    vertical-align: Top;
    background: #d2efe8;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .navbox {
    display: none;
  }
  .searchs {
    display: none;
  }
  .logo-box {
    position: relative;
    margin: 10px !important;
    /*  width: 200px; */
    height: 50px;
    margin-left: 150px !important;
    padding-left: 40px;
  }
  .nav-box {
    display: none;
  }

  .header-box {
    height: 70px;
  }

  .user-icon {
    display: none;
  }
  .burger-icon {
    display: flex;
    position: absolute;
    margin-bottom: 250px;
  }
  #searchsbox {
    position: absolute;
    margin-top: 20px !important;
    margin-left: 660px;
  }
  .user-profile1 {
    border-radius: 50%;
    height: 50px;
    width: 49px;
  }
}
@media only screen and (max-width: 1340px) {
  .searchs {
    display: none;
  }
  #searchsbox {
    margin-top: 35px;
    margin-right: 35px;
  }
  .nav-box {
    margin-right: 22px;
  }
}
@media only screen and (max-width: 1240px) {
  .logo-box {
    margin-top: 15px !important;
    margin-left: 20px !important;
    width: 200px;
    position: absolute;
  }
  /* .bm-burger-button{
     position: fixed;
     width: 26px;
     height: 20px;
     left:19px;
     top: 28px;
   } */
  .nav-box {
    display: none;
  }
  .Explore-main-div {
    display: flex;
    flex-direction: column;
    margin: 10px 0 85px 0;
  }
  .login-btn {
    position: relative;
    margin-top: 18px;
    margin-bottom: 32px;
    margin-left: 303px;
    border: none;
    color: #007a78;
    height: 35px;
    width: 80px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 16px;
    font-weight: 700;
    text-align: Center;
    vertical-align: Top;
    background: #d2efe8;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  /*   .user-profile1{
      border-radius: 50%;
      height: 50px;
      width: 49px;
      margin-left: 295px;
      margin-top: 14px;
   } */
  #searchsbox {
    position: absolute;
    margin-top: 28px !important;
    margin-left: 250px;
  }
  .mob-search-box {
    display: flex;
    align-items: center;
    width: 319px;
    height: 30px;
    background: rgba(213, 213, 213, 0.35);
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .mobile-drop-li {
    padding-right: 0px;
  }

  .my-profile-mob-wrap {
    position: fixed;
    top: 55px;
    left: 28.5%;
    background: #fff;
    height: 100vh;
    width: 100%;
    transition: all 0.3s ease-in;
  }
  .mtp-ctnt {
    top: 82px !important;
  }
  .pop-up-mob {
    position: fixed;
    top: 40px;
    /* left: 28.5%; */
    background: #fff;
    height: 100vh;
    width: 100%;
    overflow: scroll;
  }

  .blog-pop-up-mob {
    position: fixed;
    top: 40px;
    /* left: 28.5%; */
    background: #fff;
    height: 580px;
    width: 100%;
    overflow: scroll;
  }
  .pop-up-mob img {
    padding-bottom: 2px;
  }
  .pop-up-mob span {
    padding-left: 4px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #212121;
  }

  .pop-up-mob .pop-content-wrap {
    padding-left: 51px;
    min-height: 100%;
  }
  .blog-pop-up-mob .blog-content-wrap {
    padding-left: 0px;
    margin-top: 20px;
    min-height: 100%;
  }

  .pop-content-wrap {
    padding-left: 30px;
  }

  .pop-content-wrap li {
    font-size: 14px;
    color: #212121;
  }
  .pop-content-wrap img {
    padding-bottom: 2px;
  }

  .pop-content-wrap span {
    padding-left: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }

  .before-login-sec {
    margin-top: 25px;
  }
  .before-login-sec p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
  }

  .before-login-sign {
    background-color: #fff;
    color: #007a78;
    border: 2px solid;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .before-login-login {
    background-color: #007a78;
    color: #ffffff;
    border: 2px solid;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 28px;
    border-radius: 5px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (min-width: 1360px) {
  .program-wraper-head {
    border-top: 1px solid #dadada;
    height: 100vh;
    position: absolute;
    right: 14%;
    width: 360px;
  }
  .program-wraper-head-align {
    right: 27%;
  }
}
@media only screen and (min-width: 1550px) {
  .program-wraper-head {
    border-top: 1px solid #dadada;
    height: 100vh;
    position: absolute;
    right: 10.5%;
    width: 360px;
  }
  .program-wraper-head-align {
    right: 20.5%;
  }
}
