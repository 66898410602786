.new-rele-wrap {
  height: 50px;
  background: hsl(204, 100%, 14%);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  position: relative;
}

.new-rele-margin {
  margin: 0 100px;
}

.rele-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #d8d7d7;
  margin: 0 50px;
  position: absolute;
}

.rele-text img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  margin-bottom: 5px;
}
/* @keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
} */
.date {
  color: #f3e560;
}

/**marquee/
/* marquee div container */
.marquee {
  font-size: 18px;
  color: #fff;
  font-family: "montsearrat";
  height: 50px;
  overflow: hidden;
  background-color: #002a46;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
/* nested div inside the container */
.marquee div {
  display: block;
  width: 200%;
  position: absolute;
  overflow: hidden;
  animation: marquee 18s linear infinite;
}
/* span with text */
.banner-offer-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
}
/* keyframe */

/*slider start*/

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

/*slider end*/
/*over lay*/

.new-rele-overlay {
  background-color: hsla(0, 0%, 0%, 0.582);
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
}

.new-rele-popup-wrap {
  width: 725px;
  height: 573px;
  background: #fff;
  margin: auto;
  margin-top: 10%;
  position: relative;
}
.new-rele-top {
  display: flex;
  background: #fbfbfb;
  border-bottom: 1px solid #c4c4c4;
  padding: 18px 50px;
}
.new-rele-top h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;

  color: #007a78;
}

.rele-des {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  padding: 20px 60px;
  color: #212121;
}

.rele-des span {
  font-weight: 700;
}

.rele-note-wrap {
  padding: 0px 60px;
}
.list-item-rele {
  list-style: disc;
  margin: 0 0 0 10px;
}

.note {
  background: #f0faff;
  padding: 20px;

  border: 1px dashed #2b8bc1;
}

.note h3 {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;

  color: #2b8bc1;
}

.happy-learn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;

  display: flex;
  align-items: center;
  padding: 15px 60px;
  color: #007a78;
}

.new-rele-close-btn {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.new-release-fevstiv-offer-wrap {
  background: linear-gradient(90deg, #be1875 0.8%, #451abf 100%);
  font-size: 18px;
  color: #fff;
  font-family: "montsearrat";
  height: 50px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
}

.claim-btn {
  width: 210px;
  height: 40px;
  background: linear-gradient(180deg, #fdc830 0%, #f37335 47.4%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;

  color: #ffffff;
  margin-left: 35px;
}

@media screen and (max-width: 490px) {
  .banner-offer-text {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }
  .new-rele-overlay {
    height: 140vh;
  }
  /* .new-rele-wrap {
    position: fixed;
  } */
  .marquee div {
    animation: marquee 8s linear infinite;
  }

  .new-rele-top h2 {
    font-size: 16px;
  }

  .new-rele-top {
    padding: 18px 10px;
  }

  .rele-des {
    padding: 28px 28px;
    font-size: 14px;
  }

  .rele-note-wrap {
    padding: 0px 28px;
  }
  .note ul li {
    font-size: 14px;
    padding-bottom: 5px;
  }
  .happy-learn {
    font-size: 14px;
    padding: 15px 26px;
  }
  .new-rele-popup-wrap {
    height: fit-content !important;
  }

  .mobile-text {
    display: none;
  }
  .new-rele-popup-wrap {
    width: 100%;
    margin-top: 30%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .rele-text {
    padding: 5px 10px;
    font-size: 12px;
  }
  .new-release-fevstiv-offer-wrap{
    height: 72px;
    padding: 0 15px;
  }
  .claim-btn{
    font-size: 13px;
    margin-left: 15px;
    width:115px;
  }
}
