/* .Connnection-Lose{
   
} */
.Connection-Main-Div{
    display: flex;
    flex-direction: column;
    background-image:  url(../../../images/Connectionerror/background-1.jpg);
    background-position: center;
    background-size: cover;
    min-height:100vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
   
}
@media  only screen and (max-width: 560px){
    .Connection-Main-Div >img{
        height: 150px;
        width: 150px;
    }
}