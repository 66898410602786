	/*
  	Flaticon icon font: Flaticon
  	Creation date: 12/03/2019 17:19
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-open-book:before { content: "\f100"; }
.flaticon-mortarboard:before { content: "\f101"; }
.flaticon-university:before { content: "\f102"; }
.flaticon-exam:before { content: "\f103"; }
.flaticon-books:before { content: "\f104"; }
.flaticon-diploma:before { content: "\f105"; }
.flaticon-earth-globe:before { content: "\f106"; }
.flaticon-book:before { content: "\f107"; }
.flaticon-blackboard:before { content: "\f108"; }
.flaticon-professor:before { content: "\f109"; }
.flaticon-school-material:before { content: "\f10a"; }
.flaticon-library:before { content: "\f10b"; }
.flaticon-student:before { content: "\f10c"; }
.flaticon-books-1:before { content: "\f10d"; }
.flaticon-student-1:before { content: "\f10e"; }
.flaticon-blackboard-1:before { content: "\f10f"; }
.flaticon-ink:before { content: "\f110"; }
.flaticon-bell:before { content: "\f111"; }
.flaticon-exam-1:before { content: "\f112"; }
.flaticon-abacus:before { content: "\f113"; }