

  /* search scroll */

  #search-scrolls::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  #search-scrolls::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
  }
   
  /* Handle */
  #search-scrolls::-webkit-scrollbar-thumb {
    background: #007a78; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  #search-scrolls::-webkit-scrollbar-thumb:hover {
    background: #007a78 ; 
  }

.searchcrd-w{
    margin: 0px 110px;
    cursor: pointer;
}
.search-height{
    height: 67vh;
    overflow-y: scroll;
    width: 94%;
    
}



#searchvariant{
    margin-left: 5.5%;
}
@media only screen and (min-width: 908px) and (max-width: 1500px){

   #searchvariant{
    margin-left: 6.5%;
};
}

.searchall{
    background: none !important;
}
/* .Search-div-item-main{
    overflow-y: scroll;
} */
.searchall .nav-link.active, .searchall .show>.nav-link {
    background-color:transparent !important;
    color: #007a78 !important;
    font-weight: 600;
}
.search-crd-title{
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 4px;
}
.search-crd{
    width:230px;
    height:130px
}
.search-type-free{
   color: #007a78;
  font-weight:500 ;
    width: 80px;
    height: 21px;
    border-radius: 5px;
    padding: 3px;
    font-size: 20px;
    font-weight: 700;
    font-family: montserrat;

}
.search-type-premimum{
    /* background-color: #D4AF37; */
    color: #007a78;
    width: 82px;
    height: 21px;
    border-radius: 5px;
    padding: 3px;
    font-size: 20px;
    font-weight: 700;
    font-family: montserrat;

}

.no-res-img{
    margin-left: 35%;
   
}

.sr-insr-title{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    

}
.margin-top-16{
    margin-top: -16px;
}

.search-title{
    margin-left: 116px;
    margin-top: 45px;
    margin-bottom: 45px;
    font-weight: 700;
    
}

.no-result-found-msg{
    font-family: montserrat;
    font-size: 22px;
    color: #007a78;
    font-weight: 600;
    margin-left: 8%;
    margin-top: 1%;
    /* text-align: center; */
}


@media screen and (max-width:1024px) {
    .no-res-img {
        margin-left: 16%;
    }
    
}
@media only screen and (max-width: 576px) {

   
    .search-title{
        margin-left: 116px;
        margin-top: 35px;
        margin-bottom: 35px;
        font-weight: 700;
        
    }
    /* .imgsearch{
        width:50%;
    } */
    .search-height{
       padding: 0 15px;
    }
    .sr-insr-title{
        font-size: 15px;
    }
    .search-type-premimum{
        font-size: 18px;
    }

    .search-crd-title{
        margin-top: 5px;
     margin-bottom: 8px;
    }

    .search-title{
        margin-left: 24px;
        font-weight: 700;
    }
    .searchcrd-w{
        margin: 0px 10px;
        cursor: pointer;
    }
    .search-crd-title{
        font-size: 16px;
    }
    .search-crd{
        width:115px;
        height: 75px;
    }

    .no-res-img{
        margin-left: 0;
    }
    
}