.footernew{
    background: rgba(232,247,243,.9) !important;
    width: 100%;
    height: max-content;
    padding: 5px 100px 5px 100px;
    /* margin-top: 80px; */
}
.ft-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

}
.ft-top img{
    width: 150px;
    height: 50px;
}
.ft-txt{
color: #007A78;
font-family:montserrat;
font-style: normal;
font-weight: 600;
font-size: 18px;
margin-left: 2rem;
}
.ft-txts{
    color: #007A78;
    font-family:montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: 2rem;
    text-transform: capitalize;
    }
.ft-botm{
    font-size: 14px;
    color: #007A78;
    margin-right: 14px;
    font-family: montserrat;
}
.ft-botm2{
    font-size: 14px;
    margin-right: 14px;
    color: #007A78;
    font-family: montserrat;
   
}

.foo-right{
    display: flex;
}

.foo-soc-hov a:hover{
color: #212121;
}

.foo-soc-hov img{

    width: 35px;
}

.instagran-lnk{
    margin-top: 4px;
    width: 28px;
    margin-right: 14px;
    margin-left: 8px;
}
.youtube-lnk{
    margin-left: 12px;
    
}

.footer-social-icon-sec{
    align-items: baseline;
    justify-content: space-between;
}


@media only screen and (max-width: 908px){
    .footernew{
        background-color:rgba(232,247,243,.9) !important;
        width: 100%;
        height: max-content;
        padding: 30px 16px;
    }
    .ft-top img{
        width: 150px;
        height: 50px;
    }
    .ft-txt{
        color: #007A78;
      font-weight: 700;
        font-style: normal;
        font-size: 13px;
        margin-left: 15px;
        margin-top: 10px;
        line-height: 30px;
        }
        .ft-txts{
            color: #007A78;
         
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            margin-left: 15px;
            }
        .ft-botm{
            font-size: 10px;
            color: #007A78;
            margin: 0px 8px;
            /* width: 100%; */
            /* margin-right: 1rem; */
        }
        .btn-link{
            margin: 0px 20px;
        }
        .ft-botm2 {
            font-size: 12px;
            margin-left: 13px;
        }
    .ft-top{
        margin-top: 0px;
    }

    .ml-md-4{
        margin-bottom: 20px;
        
    }

    .instagran-lnk{
    
        width: 22px;
   
    }

    .foo-col{
        flex-direction: column;
    }
    .foo-right{
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }


    .footer-terms-condi-sec{
        margin-left: 5%;
    }
   /*  .footer-social-icon-sec{
        
    } */
  }