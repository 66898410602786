.ttr-separator-outer {
    overflow: hidden;
}
.ttr-separator {
    display: inline-block;
    height: 2px;
    width: 80px;
    margin-bottom: 10px;
    position: relative;
}
.ttr-separator.style-liner {
    width: 20px;
}
.ttr-separator.style-icon {
    width: 30px;
    height: auto;
    text-align: center;
    font-size: 20px;
}
.ttr-separator[class*="style-"]:after,
.ttr-separator[class*="style-"]:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 40px;
    width: 70px;
    height: 2px;
    background: #eee;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.ttr-separator[class*="style-"]:before {
    left: auto;
    right: 40px;
}
.ttr-separator.style-skew {
    width: 15px;
    height: 10px;
    margin-left: 1px;
    margin-right: 1px;
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg);
}
.ttr-separator.style-skew[class*="style-"]:after,
.ttr-separator.style-skew[class*="style-"]:before {
    width: 80px;
    height: 4px;
    left: 20px;
    -moz-transform: translateY(-50%) skewX(-10deg);
    -webkit-transform: translateY(-50%) skewX(-10deg);
    -o-transform: translateY(-50%) skewX(-10deg);
    -ms-transform: translateY(-50%) skewX(-10deg);
    transform: translateY(-50%) skewX(-10deg);
}
.ttr-separator.style-skew[class*="style-"]:before {
    right: 20px;
    left: auto;
}
.ttr-separator.bnr-title{
	height:1px;
	width: 155px;
	opacity: 0.5;
}
.ttr-separator.bnr-title:before {
    height: inherit;
    right: -80px;
    width: 25px;
}
.ttr-separator.bnr-title:after {
    height: inherit;
    right: -90px;
    top: 0;
    width: 6px;
}
.ttr-separator.bnr-title:before,
.ttr-separator.bnr-title:after{
	position:absolute;
	content:"";
	background-color:inherit;
}
.ttr-separator.bnr-title i {
    background-color: inherit;
    display: block;
    height: inherit;
    position: absolute;
    right: -50px;
    width: 45px;
}